import Vue from 'vue'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async getAllBrandsByUserHasGroupId({ commit }, {
      userHasGroupId, offset, limit, searchQuery,
    }) {
      try {
        const response = await Vue.prototype.$axios.get(`/brands/${userHasGroupId}?offset=${offset - 1}&limit=${limit}&search=${searchQuery}`, {
          headers: {
            userHasGroupId,
          },
        })
        return response
      } catch (e) {
        throw new Error(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async getAllBrandsByUserHasGroupIdAdmin({ commit }, {
      userHasGroupId, offset, limit, searchQuery,
    }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/brands/${userHasGroupId}?offset=${offset - 1}&limit=${limit}&search=${searchQuery}`, {
          headers: {
            userHasGroupId,
          },
        })
        return response
      } catch (e) {
        throw new Error(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async checkIfGroupHasAgencyPermission({ commit }, {
      userHasGroupId,
    }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/brands/checkUserPermission/${userHasGroupId}`, {
          headers: {
            userHasGroupId,
          },
        })
        if (response.status === 200) {
          return response.data.data.results
        }
        return null
      } catch (e) {
        throw new Error(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async CreateBrand({ commit }, { brandName }) {
      try {
        const response = await Vue.prototype.$axios.post('brands', {
          name: brandName,
        })
        return response
      } catch (e) {
        throw new Error(e)
      }
    },
  },
  getters: {
  },
}
