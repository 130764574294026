<template>
  <div>
    <b-modal
      ref="add-brand"
      centered
      hide-footer
      hide-header
      class="position-relative"
      size="lg"
      @hide="hideModal"
    >
      <div
        class="m-2"
      >
        <validation-observer ref="addBrand">
          <b-form-row>
            <!-- add venue heading -->
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="mb-2"
            >
              <div
                class="d-inline-block d-flex justify-content-center align-content-center align-items-center"
              >
                <span class="font-weight-bolder heading1">
                  Create Brand
                </span>
              </div>
              <div
                class="closediv"
              >
                <b-button
                  variant="transparent"
                  class="closebtn"
                  @click="hideModal()"
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <!-- Venue Name input -->
            <b-row
              class=" w-100"
            >
              <b-col
                lg="9"
                xl="9"
                md="9"
                sm="12"
                class="mx-auto"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="venue-name"
                  name="Brand Name"
                  rules="required"
                >
                  <b-form-group
                    label="Brand Name"
                    class="label"
                  >
                    <b-form-input
                      id="venue-name"
                      v-model="brandName"
                      placeholder="Enter Brand Name"
                      trim
                      class="input1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- submit buttons -->
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="d-flex justify-content-center align-items-center"
            >
              <div
                class="form-group w-50"
              >
                <b-button
                  block
                  variant="primary"
                  :disabled="spinner"
                  type="submit"
                  @click="addBrand($event)"
                >
                  <div
                    v-if="spinner"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  Add
                </b-button>
              </div>
            </b-col>
          </b-form-row>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BModal,
  BFormInput,
  BFormGroup,
  VBModal,
  BCol,
  BSpinner,
  BFormRow,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/common/global/functions'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    BFormRow,
    BButton,
    BFormInput,
    BFormGroup,
    BModal,
    BCol,
    BRow,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      spinner: false,
      required,
      AccountTypes,
      brandName: '',
    }
  },
  methods: {
    resetDataWhenModalClosed() {
      this.brandName = ''
    },
    show() {
      this.$refs['add-brand'].show()
    },
    hideModal() {
      this.resetDataWhenModalClosed()
      this.$refs['add-brand'].hide()
    },
    async addBrand() {
      this.spinner = true
      try {
        const success = await this.$refs.addBrand.validate()
        if (success) {
          const response = await this.$store.dispatch('brands/CreateBrand', {
            brandName: this.brandName,
          })
          if (response.data.statusCode === 201) {
            showToast('Create Brand', 'Brand created successfully', 'success')
            this.hideModal()
            this.$emit('reloadBrands')
          } else {
            showToast('Create Brand', 'Something Went Wrong!', 'danger')
            this.hideModal()
            this.$emit('reloadBrands')
          }
        }
      } catch (e) {
        console.log(e)
        this.$swal('Enter Valid data')
      } finally {
        this.spinner = false
      }
    },
  },
}
</script>
  <style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
  <style lang="scss">
  .vs__dropdown-menu{
    max-height: 250px !important;
  }
  .form{
    padding-inline: 10px;
  }
  .add{
    position: relative;
  }
  .upload{
    position: absolute;
    bottom: 20px;
    right: 54px;
  }
  .head{
    font-size: 14px;
    line-height: 24px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .spinner{
    text-align: center;
    z-index: 1070;
  }
  .compaignlabel{
    font-size: 10px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .headingVenue{
    font-size: 20px;
    color: black;
    font-weight: bolder;
  }

  .pac-container {
    z-index: 1041;
  }
  @media (max-width: 990px) {
  .enter-manually{
    width: 100% !important;
    padding-top: 5px;
  }
  }

  @media (max-width: 550px) {
  .enter-manually{
    font-size: 12px;
  }
  .headingVenue{
    font-size: 18px;
  }
  .modal{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  }

  </style>
